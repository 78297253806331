body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
  background-color: #000;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
}

@font-face {
  src: url(assets/fonts/FontAwesome.ttf);
  font-family: FontAwesome;
}

@font-face {
  src: url(assets/fonts/EvilIcons.ttf);
  font-family: EvilIcons;
}

@font-face {
  src: url(assets/fonts/Feather.ttf);
  font-family: Feather;
}
