body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
  background-color: #000;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
}

@font-face {
  src: url(/static/media/FontAwesome.b06871f2.ttf);
  font-family: FontAwesome;
}

@font-face {
  src: url(/static/media/EvilIcons.140c53a7.ttf);
  font-family: EvilIcons;
}

@font-face {
  src: url(/static/media/Feather.ca9ce9ff.ttf);
  font-family: Feather;
}

